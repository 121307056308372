/*!
 ***************  CSS PERSONALIZADO *********************************
 */

body{
  padding-top: 75px;
}

label{
  font-weight: bold;
  &.form-check-label, &.checkbox-inline{
    font-weight: normal;
  }
}

 /*! ETIQUETAS <A> GENERALES */
 a {
   text-decoration: none;
   &:hover, &:focus {
     color: #11d07b;
   }
  }
  
/*!  FIN ETIQUETAS <A> GENERALES */

.btn-primary{
  color: #fff;
  &:hover, &:focus, &:disabled, &.disabled {
    color: #fff;
  }
}

/*! TABLE PRINCIPAL PANEL */


.person-svg--margin {
  margin-top: -3px;
}
/*! END TABLE PRINCIPAL PANEL */

/*! END SPECIAL LABELS */
.logout-button {
  font-size: 0.9rem;
  background: none;
  border: none;
}

table.dataTable {
  width: 100% !important;
    & thead th, &thead td{
      padding: 10px 10px !important;
    }
    & tbody th, & tbody td {
      padding: 10px 5px !important;
    }
  }
  
  .visible-on-small{
    display: none;
    @media screen and (max-width: $large-lg){
      display: block;
    }
  }

  .visible-on-sm{
    display: none;
    @media screen and (max-width: $small-sm){
      display: block;
    }
  }

  .hidden-on-small{
    display: block;
  }
  .nav-stacked > li {
    float: none;
  }
  ul.nav.nav-pills.nav-stacked  {
    flex-direction: column;
  }
  .btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
  }

  /*! FIN STYLE SPINNER INICIAL */

@media (max-width: $large-lg) { 
  .hidden-on-small{
    display: none;
  }

  .scroll-list {
    max-height: 280px;
    overflow-y: auto;
  }
}
 /*! STYLE ICONO DE PERFIL Y NOMBRE DE USUARIO AL LADO DE MENU EN MOVIL  */
.profile-name {
  max-width: 115px;
  height:20px;
  text-decoration: none !important;
  overflow: hidden; 
  white-space: nowrap;
  text-overflow: ellipsis;
}

.modal-fullscreen {
    z-index: 4000;
    .modal-dialog {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        border: none;
    }
    .modal-content {
        height: 100%;
        border-radius: 0;
        border: none;
        border-radius: 0;
        box-shadow: inherit;
    }
}

a {
  transition: all .35s;
  &:hover, &:focus{
        color: #11d07b;
    }
}

.toast {
    text-align: center;
    opacity: 1 !important;
}

.ul-clean-errors {
  div > ul, & > ul{
    list-style-type: none;
    margin: 0px;
    padding: 0px;
  }
}

.ulclean {
    div > ul, & > ul{
      list-style-type: none;
      margin: 0px;
      padding: 0px;
  }
}

.options-box {
    border: 2px dashed #ccc;
    background-color: #f5f5f5;
}

.right-border {
    border-right: solid 1px #f6f6f6;
}

.separation {
    margin-top: 50px;
}
.separationDbl {
    margin-top: 120px;
}
.w100 {
    width: 100%;
}
.hand {
    cursor: pointer;
}
.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}

.no-js {
    #pageLoader {
        display: none;
    }
}
.js {
    #pageLoader {
        display: block;
        position: absolute;
        left: 100px;
        top: 0;
    }
}
.navbar-default {
    background-color: #FFF;
    border-color: #FFF;
    border-color: rgba(34,34,34,.05);
    background-color: #fff;
    -webkit-transition: all .35s;
    -moz-transition: all .35s;
    transition: all .35s;
    .navbar-header {
        .navbar-brand {
            text-transform: uppercase;
            font-family: 'Open Sans','Helvetica Neue',Arial,sans-serif;
            font-weight: 700;
            color: #039e59;
            &:focus {
                color: #039e59;
            }
        }
    }
    .nav {
        >li {
            >a {
                text-transform: uppercase;
                font-size: 13px;
                font-weight: 500;
                color: #222;
                &:focus {
                    text-transform: uppercase;
                    font-size: 13px;
                    font-weight: 500;
                    color: #222;
                    &:hover {
                        color: #039e59;
                    }
                }
                &:hover {
                    color: #039e59;
                }
            }
        }
        >li.active {
            >a {
                color: #039e59 !important;
                background-color: transparent;
                &:focus {
                    color: #039e59 !important;
                    background-color: transparent;
                    &:hover {
                        background-color: transparent;
                    }
                }
                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
}

.maxnavbar {
    float: left;
    margin-right: 5px;
    border-radius: 50%;
}
a.navbar-brand {
    font-weight: 700;
    font-size: 22px;
    color: #081432 !important;
}
a.logo-img {
    width: 160px;
    background-image: url(../img/web/logo.png);
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: 20px 8px;
}

.navbar-default {
    .navbar-header {
        .navbar-brand {
            &:hover {
                color: #039e59;
            }
        }
    }
}

footer {
    width: 100%;
    height: auto;
    ul {
        list-style-type: none;
        padding: 0px;
        margin-top: 10px;
    }
}
.footer {
    width: 100%;
    height: auto;
    background-color: #566755;
    color: #FFF;
    padding: 5px 0px 40px 0px;
}
.title-footer {
    font-weight: normal;
    font-size: 16px;
}
.sub-footer {
    background-color: #fbfbfb;
    padding: 30px 0px 30px 0px;
}
#search-post-ticket {
    .form-control {
        height: 60px;
        font-size: 23px;
    }
}
div.sliderTable {
    display: none;
}
table.dataTable {
    tbody {
        td.no-padding {
            padding: 0;
        }
    }
}

#input-newsletter {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #232740;
    background-image: none;
    border: 1px solid #039e59;
    border-radius: 4px;
}
#button-newsletter {
    display: block;
    width: 80%;
    height: 34px;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #039e59;
    background-color: transparent;
    background-image: none;
    border: 1px solid #039e59;
    border-radius: 4px;
    margin-top: 10px;
}
.input-login {
    display: block;
    width: 100%;
    height: 44px;
    padding: 10px 12px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #5c838e;
    background-color: #ededed;
    background-image: none;
    border: 1px solid #ededed;
    border-radius: 4px;
}
.btn-fb {
    color: #FFF;
    background-color: #5379c1;
    border-color: #5379c1;
    padding: 5px 10px;
    i {
        padding-right: 20px;
    }
}
a.btn-fb {
    &:hover {
        text-decoration: none !important;
    }
}
.btn {
    .btn-fb {
        &:hover {
            text-decoration: none !important;
        }
    }
}

a.up-page {
    color: #039e59 !important;
}

.c9ec5ec {
    color: #9ec5ec;
}
.c091532 {
    color: #091532;
}
.c081432 {
    color: #081432 !important;
}
.image-profile {
    background-color: #081432;
    max-width: 150px;
    height: 150px;
    max-height: 100%;
    width: 100%;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    text-align: center;
    margin-bottom: 10px;
    strong {
        color: #fff;
        font-size: 50px;
        position: relative;
        top: 40px;
    }
}
.title-page {
    color: #081432;
    margin-bottom: 30px;
}
.content-login {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    padding: 20px 40px;
    strong {
        color: #5c838e;
    }
}
.title-event {
    border: none;
    border-bottom: solid 1px #ccc;
    box-shadow: none;
    border-radius: 0px;
}

textarea {
    resize: none;
}
.btn-success-m {
    font-size: 16px;
    padding: 11px 40px;
    background-color: $link-color;
    border-color: $link-color;
}
.btn-info-m {
    font-size: 16px;
    padding: 10px 40px;
    border-radius: 20px;
    background-color: #4a90e2;
    border-color: #4a90e2;
}
.btn-info {
    background-color: #4A90E2 !important;
}
a.btn-publish-event {
    color: #fff !important;
}
.btn-publish-event {
    background-color: #43AA8B;
    border: solid 1px #43AA8B;
    border-radius: 4px;
    &:hover {
        background-color: #039e59 !important;
        border: solid 1px #039e59 !important;
    }
}
.cl039e59 {
    color: #039e59;
}
.cfff {
    color: #FFF !important;
}

.iconHome {
    font-size: 46px;
    color: #039e59;
}
.contentfbf {
    background-color: #fbfbfb;
}
.panel {
    margin-bottom: 0px;
}
#formcontact {
    .form-control {
        min-height: 52px;
    }
}

.headerEvent {
    position: relative;
    width: 100%;
}
.titleEvent {
    position: absolute;
    z-index: 999;
    color: #fff;
    width: 100%;
    background-color: rgba(8,8,8,0.3);
}
.titleEventEdit {
    position: absolute;
    z-index: 999;
    color: #fff;
    width: 100%;
    top: 40%;
}
.detailEvent {
    position: absolute;
    bottom: 10%;
    padding: 0px 10px;
}
.container-payment {
    border: solid 1px #e3e3e3;
    border-radius: 5px;
    box-shadow: 2px 2px 3px;
    .input-group-addon {
        background-color: #fff !important;
    }
}
.contentInfoUser {
    border: solid 1px #ccc;
    padding: 20px;
    border-radius: 4px;
}
.imageFooter {
    max-width: 190px;
    min-height: 140px;
    padding: 10px;
    display: block;
    margin: auto;
}
.ui-widget-header {
    background: #57AD68 !important;
}
.jFiler-input {
    max-width: 300px;
}


table.tableEvents {
    th {
        font-size: 24px;
        color: #081432;
    }
    .caret {
        float: right;
        margin-top: 10px;
    }
    li {
        border-bottom: solid 1px #e3e3e3;
        padding: 5px 0px;
    }
}
.tableItems {
    .caret {
        float: right;
        margin-top: 10px;
    }
    th {
        background-color: #039e59;
        color: #fff;
    }
}
#tableEvent_wrapper {
    color: #908f8f !important;
}
#tableEvent_length {
    color: #908f8f !important;
}
#tableEvent_filter {
    color: #908f8f;
}
ul.dropdown-menu {
    li {
        &:last-child {
            border: none !important;
        }
    }
}
.not-active-link {
    pointer-events: none;
    cursor: default;
}
.active-link {
    pointer-events: none;
    cursor: default;
}
.greylink {
    color: #b3b3b3;
}
.section-comission {
    input[type="radio"] {
        display: none;
        &:checked {
            + {
                label {
                    background-color: #449D44;
                    border: 1px solid #449D44;
                }
            }
        }
    }
    label {
        background: #091532;
        border: 1px solid #091532;
        padding: 2px 10px;
        display: block;
        margin: auto;
        width: 170px;
        margin-top: 10px;
        color: #FFF;
    }
}

@media screen and (min-width: 450px) {
    .toast {
        width: 450px !important;
    }
    #startForm {
        margin-top: 50px;
    }
}

@media(min-width:768px) {
    .navbar-default {
        border-color: rgba(34,34,34,.05);
        background-color: rgba(255, 255, 255, .7);
        .navbar-header {
            .navbar-brand {
                color: rgba(255,255,255,.7);
                &:hover {
                    color: #fff;
                }
                &:focus {
                    color: #fff;
                }
            }
        }
        .nav {
            >li {
                >a {
                    color: #222;
                    &:focus {
                        color: #222;
                        &:hover {
                            color: #039e59;
                        }
                    }
                    &:hover {
                        color: #039e59;
                    }
                }
            }
        }
    }
    .navbar-default.affix {
        border-color: rgba(34,34,34,.05);
        background-color: #fff;
        .navbar-header {
            .navbar-brand {
                font-size: 22px;
                color: #039e59;
                &:hover {
                    color: #039e59;
                }
                &:focus {
                    color: #039e59;
                }
            }
        }
        .nav {
            >li {
                >a {
                    color: #222;
                    &:focus {
                        color: #222;
                        &:hover {
                            color: #039e59;
                        }
                    }
                    &:hover {
                        color: #039e59;
                    }
                }
            }
        }
    }
}

@media (max-width:320px) {
    .btn-success-m {
        font-size: 13px;
        padding: 10px 4px;
    }
}

@media screen and (max-width: 765px) {
    .right-border {
        border: none;
    }
    .ip-option {
        margin-top: 10px;
    }
}

@media screen and (min-width: 1280px) {
    .container-fluid {
        margin-left: 0 auto !important;
        margin-right: 0 auto !important;
    }
}



/* The switch - the box around the slider */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

/* Hide default HTML checkbox */

/* The slider */

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
}

input {
  &:checked + .slider {
    background-color: $link-color;
  }

  &:focus + .slider {
    box-shadow: 0 0 1px $link-color;
  }

  &:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;

  &:before {
    border-radius: 50%;
  }
}

#cookie_directive_container{
  z-index: 999999;
  background-color: rgba(20,20,20, .5);
}

#navbar-vivetix{
  background-color:#fff;
  z-index: 999;
}

/*Social bar*/
#social-block{
  margin: 10px;
  .buttons{
      position: relative;
  }

  img{
    width: 32px;
  }

  a {
      text-decoration: none;
  }

  .overlay{
      position: absolute;
      left: 0;
      right: 0;
      top:0;
      background: rgba(255,255,255,0.8);
      font-size: 120%;
      padding: 0.5rem 4rem;
      bottom: 0;
      cursor: pointer;
  }
}


.tex-separator{
  font-size: 18px;
  overflow: hidden;
  margin: 18px 5px;

  &::before, &::after{
    background-color: #aaa;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  &::before {
    right: 0.5em;
    margin-left: -50%;
  }
  &::after {
    left: 0.5em;
    margin-right: -50%;
  }
}


.title-section {
  max-width: 360px;
  padding: 5px 0;
  color: #fff;
  text-align: center;
  background: linear-gradient(-250deg, #039e59 90%, transparent 10%);
  font-weight: bold;
}



/*!
 ***************  CSS PERSONALIZADO *********************************
 */

body{
  padding-top: 75px;
}

 /*! ETIQUETAS <A> GENERALES */
 a {
   text-decoration: none;
   &:hover, &:focus {
     color: #11d07b;
   }
  }
  
/*!  FIN ETIQUETAS <A> GENERALES */

.btn-primary{
  color: #fff;
  &:hover, &:focus, &:disabled, &.disabled {
    color: #fff;
  }
}

/*! TABLE PRINCIPAL PANEL */


.person-svg--margin {
  margin-top: -3px;
}
/*! END TABLE PRINCIPAL PANEL */


/*! END SPECIAL LABELS */
.logout-button {
  font-size: 0.9rem;
  background: none;
  border: none;
}

 /*! STYLE ICONO DE PERFIL Y NOMBRE DE USUARIO AL LADO DE MENU EN MOVIL  */
 .profile-name {
   max-width: 115px;
   height:20px;
   text-decoration: none !important;
   overflow: hidden; 
   white-space: nowrap;
   text-overflow: ellipsis;
  }
  
  /*! DELETE STYLE BUTTON  */
.button--not {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
}
.period{
  &:hover, &:active,&.active{
    color: #039e59;
  }
}

.mce-flow-layout-item {
  margin: 0 !important;
}

.pl-nav{
  padding-left: 42px;
}

.offcanvas {
  z-index: 1055;
}

.navbar-toggler{
  padding: 0.5rem;
}

.navbar-toggler-icon{
  display: block;
  height: 26px;
  width: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: $primary;
    &.line1 {
      transform-origin: 0% 0%;
      transition: transform 0.4s ease-in-out;
    }
    &.line2 {
      transition: transform 0.2s ease-in-out;
    }

    &.line3 {
      transform-origin: 0% 100%;
      transition: transform 0.4s ease-in-out;
    }
  }
}

.navbar-toggler:not(.collapsed){
  .line {
    &.line1 {
      transform: translate(7px, 0) rotate(45deg);
    }

    &.line2 {
      transform: scaleY(0);
    }

    &.line3 {
      transform:  translate(7px, 0) rotate(-45deg);
    }
  }
}